import { mainService } from '~/services/main';
import { Main } from '~/models/main';

export const useMainPageState = () => ({
	mainPageState: useState<Main.Model>('mainPageState', () => Main.modelState),
	mainPageLoaded: useState<boolean>('mainPageLoaded', () => false),
	mainPagePending: useState<boolean>('mainPagePending', () => true),
});

export const useMainPage = () => {
	const { mainPageState, mainPageLoaded, mainPagePending } = useMainPageState();

	/**
	 * Fetch main page
	 */
	const fetchMainPage = async () => {
		if (mainPageLoaded.value) return;

		mainPagePending.value = true;

		mainPageState.value = await mainService.fetch();

		mainPageLoaded.value = true;
		mainPagePending.value = false;
	};

	/**
	 * Refresh main page
	 */
	const refreshMainPage = async () => {
		mainPageLoaded.value = false;

		await fetchMainPage();
	};

	return {
		mainPageState,
		mainPagePending,

		fetchMainPage,
		refreshMainPage,
	};
};
