import type { Main } from '~/models/main';
import type { ServicesGroup } from '~/models/services-group';

const collection = 'main';

export const mainService = {
	fetch: async (): Promise<Main.Model> => {
		const { getSingletonItem } = useDirectusItems();

		const response = await getSingletonItem<
			Omit<Main.Model, 'servicesGroup'> & {
				servicesGroup: { services_group_id: ServicesGroup.Model }[];
			}
		>({
			collection,
			params: {
				fields: [
					'*',
					'imageLeft.*',
					'imageRight.*',
					'servicesLink.url',
					'servicesGroup.services_group_id.*',
				],
			},
		});

		/** Resolve services group */
		const servicesGroup = response.servicesGroup.reduce((acc, { services_group_id }) => {
			acc.push(services_group_id);
			return acc;
		}, [] as ServicesGroup.Model[]);

		return { ...response, servicesGroup };
	},
};
