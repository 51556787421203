<template lang="pug">
div(class='MainContainer')
	div(class='Gallery')
		div(class='Gallery--imageLeft')
			UiImage(:image='mainPageState.imageLeft', width='380', lazy)
		div(v-if='greaterThan("mobileMedium")', class='Gallery--imageRight')
			UiImage(:image='mainPageState.imageRight', width='250', lazy)

	div(class='Advantages')
		template(v-for='({ type, text, icon }, index) in mainPageState.advantages', :key='index')
			div(v-if='index < 4', class='Advantage')
				div(class='Advantage--icon')
					div(class='text')
						template(v-if='type === "working"')
							span(class='extra') {{ $f.splitText(icon, 1, 1) }}
							span(class='small') {{ $f.splitText(icon, 1, 2) }}
						template(v-else-if='type === "rating"')
							span(class='extra') {{ icon }}
						template(v-else)
							span {{ icon }}
					UiIcon(
						class='icon',
						:name='advantageIcon(type)',
						:size='icon ? (greaterThan("tablet") ? "md" : "xs") : greaterThan("tablet") ? "xl" : "md"'
					)
				div(class='Advantage--text') {{ text }}

	div(class='Info')
		div(v-if='greaterThan("tablet")', class='Logo')
		h1(v-if='greaterThan("tablet")', v-html='$f.forceTransfer(header, 2)')
		UiButton(
			v-if='contacts?.whatsapp',
			variant='whatsapp',
			theme='filled',
			:size='greaterThan("mobileWide") ? "xl" : "lg"',
			iconLeft='social/whatsapp-inline',
			rounded,
			:href='contacts.whatsapp'
		) {{ mainPageState.whatsappButton }}

	div(v-if='greaterThan("desktop")', class='FlyLabels')
		div(class='LeftDash--shadow')
		UiIcon(name='main/left-dash', size='custom', class='LeftDash')
		UiIcon(name='main/center-dash', size='custom', class='CenterDash')
		UiIcon(name='main/right-dash', size='custom', class='RightDash')

Container
	SliderServices(v-if='mainPageState.servicesGroup', :groups='mainPageState.servicesGroup')
		template(#header)
			h2 {{ mainPageState.servicesTitle }}

		template(#link)
			UiButton(
				:to='mainPageState.servicesLink.url',
				:size='lessThan("mobileWide") ? "lg" : "md"',
				variant='secondary',
				iconRight='system/arrow-top-right',
				:iconRightSize='lessThan("mobileWide") ? "sm" : "xs"',
				rounded
			) Посмотреть все

Container
	UnitManager

Container
	UnitBenefits

Container
	UnitReviews

Container
	UnitConsultation(type='employees')

Container
	UnitCases

Container
	UnitReviewsGroup

Container
	UnitConsultation(type='vnzh')

Container
	UnitArticles
</template>

<script lang="ts" setup>
const { header } = useMeta();
const { contacts, fetchContacts } = useContacts();
const { mainPageState, fetchMainPage } = useMainPage();
const { lessThan, greaterThan } = usePlatform();

const advantageIcon = (type: string) => {
	switch (type) {
		case 'chart':
			return 'system/chart';
		case 'rating':
			return 'system/star';
		default:
			return null;
	}
};

await fetchContacts();
await fetchMainPage();
</script>

<style lang="less" scoped>
.MainContainer {
	max-height: 60rem;
	margin-top: 1.25rem;
	padding: @ContainerPadding;
	.relative();
	.box(grid; 100%; auto; none; 1rem; 1fr auto auto; 1fr; center; center);

	/**
	* >= 1000px and < 1200px
	*/
	@media all and (min-width: @laptop) and (max-width: (@desktop - 1px)) {
		margin: 4rem 0;
		.gridbox-row(1fr auto);
		.gridbox-gap(4rem);
	}

	/**
	* >= 1200px
	*/
	@media all and (min-width: @desktop) {
		min-height: 54rem;
		.calc(height; ~'100vh - 5rem');
		.gridbox-row(1fr auto 2fr);
	}

	& > .Gallery {
		.grid(1 2; 1 span);
		.box(grid; 100%; auto; none; 1rem; auto; 1fr; center; center);
		& > .Gallery--imageLeft {
			overflow: hidden;
			.border-radius(@BorderRadius);
			.box(inline-block; 100%; auto);
			& > img {
				object-fit: cover;
				.box(100%; 100%);
			}
		}

		/**
		* >= 600px
		*/
		@media all and (min-width: @tablet-small) {
			.box(grid; 100%; auto; none; 1rem; auto; 2fr 1fr; stretch; center);
			& > .Gallery--imageRight {
				overflow: hidden;
				.border-radius(@BorderRadius);
				.box(inline-block; 100%; auto);
				& > img {
					object-fit: cover;
					.box(100%; 100%);
				}
			}
		}

		/**
		* >= 1000px and < 1200px
		*/
		@media all and (min-width: @laptop) and (max-width: (@desktop - 1px)) {
			.grid(unset);
			.absolute(2; hidden; 0 0 0 0 );
			.box(block; auto; auto; none);
			& > .Gallery--imageLeft {
				.absolute(1; hidden; 10%);
				.border-radius(@BorderRadius);
				.box(inline-block; auto; auto);
				.calc(left; ~'50% - 40rem');
				.rotate(15);
			}
			& > .Gallery--imageRight {
				.absolute(1; hidden; 5%);
				.border-radius(@BorderRadius);
				.box(inline-block; auto; 70%);
				.calc(right; ~'50% - 35rem');
				.rotate(-4.5);
			}
		}

		/**
		* >= 1200px
		*/
		@media all and (min-width: @desktop) {
			.grid(unset);
			.absolute(2; hidden; 0 0 0 0 );
			.box(block; auto; auto; none);
			& > .Gallery--imageLeft {
				.absolute(1; hidden; none none 20%);
				.border-radius(@BorderRadius);
				.box(inline-block; auto; auto);
				.calc(left; ~'50% - 40rem');
				.rotate(15);
			}
			& > .Gallery--imageRight {
				.absolute(1; hidden; 25%);
				.border-radius(@BorderRadius);
				.box(inline-block; auto; auto);
				.calc(right; ~'50% - 38rem');
				.rotate(-4.5);
			}
		}

		/**
		* >= 1300px
		*/
		@media all and (min-width: @large-screen-small) {
			& > .Gallery--imageLeft {
				.calc(left; ~'50% - 48rem');
			}
			& > .Gallery--imageRight {
				.absolute(1; hidden;30%);
				.calc(right; ~'50% - 45rem');
			}
		}
	}

	& > .Advantages {
		.grid(2 3; 1 span);
		.box(grid; 100%; auto; none; 1rem; repeat(2, 1fr); repeat(2, auto); stretch; center);
		& > .Advantage {
			padding: 0.5rem 1rem 0.5rem 0.5rem;
			.border-radius(@BorderRadiusFull);
			.box(inline-grid; 100%; auto; @ColorWhite; 0.5rem; 1fr; 2rem 1fr; center; start);
			& > .Advantage--icon {
				.border-radius(@BorderRadiusFull);
				.box(flex; 2rem; 2rem; @ColorBase; center; center; center; nowrap row);
				& > .text {
					.text(@ColorWhite; 0.8em 0.75rem @bold; center; none);
					& > .extra {
						.box(block; auto; auto; none);
						.text(@ColorWhite; 0.8em 0.875rem @black; center; none);
					}
					& > .small {
						.box(block; auto; auto; none);
						.text(@ColorWhite; 0.8em 0.625rem @semibold; center; none);
					}
				}
				& > .icon {
					margin: -0.125rem 0 0 0.125rem;
					.text(@ColorWhite; 1.25rem 1.125rem @black);
				}
			}
			& > .Advantage--text {
				max-height: 1.875rem;
				overflow: hidden;
				.text(@ColorBase; 1.25em  0.75rem @medium);
			}
		}

		/**
		* >= 600px
		*/
		@media all and (min-width: (@tablet-small + ((@tablet - @tablet-small) / 2))) {
			.gridbox-col(repeat(4, 1fr));
			.gridbox-row(auto);
		}

		/**
		* >= 800px
		*/
		@media all and (min-width: @tablet) {
			.gridbox-col(repeat(2, 1fr));
			.gridbox-row(repeat(2, auto));
			& > .Advantage {
				padding: 1rem 2rem 1rem 1rem;
				.gridbox-gap(1rem);
				.gridbox-col(3.75rem 1fr);
				& > .Advantage--icon {
					.box(3.75rem; 3.75rem);
					& > .text {
						.text(@ColorWhite; 0.8em 1.125rem);
						& > .extra {
							.text(@ColorWhite; 1em 1.375rem);
						}
						& > .small {
							.text(@ColorWhite; 1em 0.875rem);
						}
					}
				}
				& > .Advantage--text {
					max-height: 3.75rem;
					.text(@ColorBase; 1.25em 1rem);
				}
			}
		}

		/**
		* >= 1000px
		*/
		@media all and (min-width: @laptop) {
			.grid(3 4; 1 span);
			.gridbox-col(repeat(4, 1fr));
			.gridbox-row(auto);
			& > .Advantage {
				padding: 1rem 2rem 1rem 1rem;
				.gridbox-gap(1rem);
				.gridbox-col(3.75rem 1fr);
				& > .Advantage--icon {
					.box(3.75rem; 3.75rem);
					& > .text {
						.text(@ColorWhite; 0.8em 1.125rem);
						& > .extra {
							.text(@ColorWhite; 1em 1.375rem);
						}
						& > .small {
							.text(@ColorWhite; 1em 0.875rem);
						}
					}
				}
				& > .Advantage--text {
					max-height: 3.75rem;
					.text(@ColorBase; 1.25em 1rem);
				}
			}
		}

		/**
		* >= 1200px
		*/
		@media all and (min-width: @desktop) {
			.grid(unset);
			.absolute(2; hidden; 0 0 0 0 );
			.box(block; auto; auto; none);
			& > .Advantage {
				display: none;
				max-width: 15rem;
				.absolute(1);
				&:nth-child(1) {
					top: 8%;
					display: inline-grid;
					.calc(left; ~'50% - 35rem');
				}
				&:nth-child(2) {
					bottom: 16%;
					display: inline-grid;
					.calc(left; ~'50% + 2rem');
				}
				&:nth-child(3) {
					top: 5%;
					display: inline-grid;
					.calc(right; ~'50% - 28rem');
				}
			}
		}

		/**
		* >= 1300px
		*/
		@media all and (min-width: @large-screen-small) {
			& > .Advantage {
				&:nth-child(1) {
					.calc(left; ~'50% - 40rem');
				}
				&:nth-child(2) {
					.calc(left; ~'50% + 2rem');
				}
				&:nth-child(3) {
					top: 8%;
					.calc(right; ~'50% - 30rem');
				}
			}
		}
	}

	& > .Info {
		.relative(3);
		.grid(3 4; 1 span);
		.box(grid; 100%; auto; none; 3rem; auto; 1fr; center; center);

		& > .Logo {
			.grid(1 span);
			.box(22.5rem; 6.25rem; url('@/images/Logo.svg'));
		}

		& > h1 {
			.grid(1 span);
			.text(@ColorBase; 3rem 2.5rem @bold; center; uppercase none);
		}

		& > .UiButton {
			.box(100%);
		}

		/**
		* >= 600px
		*/
		@media all and (min-width: @tablet-small) {
			& > .UiButton {
				.box(auto);
			}
		}

		/**
		* >= 1000px and < 1200px
		*/
		@media all and (min-width: @laptop) and (max-width: (@desktop - 1px)) {
			.grid(1 2; 1 span);
		}

		/**
		* >= 1200px
		*/
		@media all and (min-width: @laptop) {
			.grid(2 3; 1 span);
		}
	}

	& > .FlyLabels {
		.absolute(1; hidden; 0 0 0 0 );
		.box(block; auto; auto; none);

		& > .LeftDash {
			.absolute(1; none; 10%);
			.box(block; 26.5625rem; 26.8125rem);
			.calc(left; ~'50% - 50rem');

			&--shadow {
				.absolute(2; none; 10%);
				.box(block; 26.5625rem; 26.8125rem);
				.calc(left; ~'50% - 50rem');
				.gradient(90; @ColorBg; fade(@ColorBg, 0%); transparent);
			}
		}
		& > .CenterDash {
			.absolute(1; none; none none 5%);
			.box(block; 55.9375rem; 17.25rem);
			.calc(left; ~'50% - 22rem');
		}
		& > .RightDash {
			.absolute(1; none; 10%);
			.box(block; 20.375rem; 18.5625rem);
			.calc(right; ~'50% - 35rem ');
		}

		/**
		* >= 1300px
		*/
		@media all and (min-width: @large-screen-small) {
			& > .LeftDash {
				.calc(left; ~'50% - 55rem');
				&--shadow {
					.calc(left; ~'50% - 55rem');
				}
			}
			& > .CenterDash {
				.calc(left; ~'50% - 25rem');
			}
			& > .RightDash {
				.calc(right; ~'50% - 40rem ');
			}
		}
	}
}
</style>
