import { Common } from '~/models/common';
import { Image } from '~/models/image';
import { ServicesGroup } from '~/models/services-group';

export namespace Main {
	export type Advantage = {
		type: string;
		text: string;
		icon: string;
	};

	export type Model = Common.SingleModel & {
		title: string | null;
		advantages: Advantage[];
		whatsappButton: string | null;
		imageLeft: Image.Model;
		imageRight: Image.Model;
		servicesTitle: string | null;
		servicesGroup: ServicesGroup.Model[];
		servicesLink: { url: string | undefined };
	};

	export const modelState: Model = {
		...Common.singleModelState,
		title: '',
		advantages: [],
		whatsappButton: null,
		imageLeft: Image.modelState,
		imageRight: Image.modelState,
		servicesTitle: null,
		servicesGroup: [],
		servicesLink: { url: undefined },
	};
}
